"use client";
import Link from "next/link";
import { motion } from "framer-motion";
import CustomImage from "@components/Layout/CustomImage";

const NotFoundBody = () => {
  return (
    <>
      <motion.div
        className="relative flex items-center justify-center mx-auto md:w-[40vw] w-[70vw] aspect-square"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
      >
        <CustomImage
          src="404-error_uyuwxi.jpg"
          sizes="(max-width: 600px) 70vw, 40vw"
          fill
          alt="image"
        />
      </motion.div>

      <div className="items-center justify-center mx-auto">
        <motion.p
          className="mb-8 text-2xl font-semibold text-center text-black"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
        >
          Sorry, the content you are looking for could not be found.
        </motion.p>
        <div className="flex items-center justify-center w-full">
          <Link href="/">
            <button className="px-4 py-3 text-2xl font-bold text-white duration-200 bg-indigo-700 rounded-md hover:bg-teal-200 hover:text-black">
              BACK TO HOME
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFoundBody;
